
















































import Vue from "vue";
import Component from "vue-class-component";
import main from "@/store/modules/groups";
import { Group } from "@/types/group";
import GroupManage from "@/views/Group/Settings/Manage.vue";
import memberModule from "@/store/modules/members";
import { Dictionary } from "@/types/helpers";

@Component({
  components: {
    GroupManage,
  },
})
export default class GroupSettings extends Vue {
  get group(): Group | undefined {
    return main.group;
  }

  get pendingCount(): number {
    return memberModule.pending.length ?? 0;
  }

  activeTab = "";
  get items(): Dictionary<Dictionary<string | number>> {
    return {
      manage: {
        icon: "mdi-cog",
      },
      members: {
        icon: "mdi-account-group",
      },
      requests: {
        icon: "mdi-account-multiple-plus",
        count: this.pendingCount,
      },
    };
  }
}
